import styled from 'styled-components';

const SubscribeWidgetStyle = styled.div`
  /* border-radius: 12px; */
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  gap: 8px;

  .subscribe__title_wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .subscribe__title_wrapper {
    .oo-bell::before {
      color: #cd7764;
    }
  }

  .subscribe__title_wrapper p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--white1000);
  }

  .subscribe__input {
    width: 100%;
    display: flex;
    gap: 8px;
    /* background: #fff; */
    /* border-radius: 8px; */
    border: 1px solid var(--gray100);
    height: 44px;
    align-items: center;
    padding: 0 12px;
    color: var(--white1000);
  }

  .subscribe__input.error {
    border: 2px solid var(--flame200);
  }

  .subscribe__input.succeed {
    background: #cd7764;
    border: none;
  }

  .subscribe__input.succeed input {
    background: #cd7764;
    font-weight: 700;
    color: var(--dark100);

    &:is(
        :-webkit-autofill,
        :autofill,
        :-internal-autofill-selected,
        :-internal-autofill-previewed
      ) {
      -webkit-text-fill-color: #000;
      color: #000;
      box-shadow: none;
      /* background-color: var(--light200) !important; */
    }
  }

  .subscribe__input input {
    width: 100%;
    padding: 12px 0;
    border: none;
    background-color: transparent;
    color: var(--white1000);

    &:is(
        :-webkit-autofill,
        :autofill,
        :-internal-autofill-selected,
        :-internal-autofill-previewed
      ) {
      -webkit-text-fill-color: #fff;
      color: var(--white1000);
      box-shadow: none;
      /* background-color: #fff !important; */
    }
  }

  .subscribe__input > span {
    height: 17px;
  }

  .subscribe__input .icon::before {
    color: #ffffff99;
  }
  .subscribe__input .icon.active::before {
    color: #cd7764;
    cursor: pointer;
  }

  .subscribe__input .check_icon {
    padding: 2px;
    background: #cd7764;
    border-radius: 50%;
    border: 1px solid var(--dark100);

    &::before {
      color: var(--dark100);
    }
  }

  .error_message {
    font-size: 12px;
    color: #de0016;
  }

  .spinner {
    color: #cd7764;
  }
`;

export default SubscribeWidgetStyle;
