import { FC, memo, useMemo } from 'react';
import { usePathname } from 'next/navigation';

//* Style
import BurgerMenuStyle from './style';

//* HOC's
import { withUIContext } from '@/context';

//* Config
import { RoutesItem } from '@/helpers';

//* Components
import Text from '@/components/global/Text';
import CustomLink from '@/components/global/CustomLink';
import Button from '@/components/global/Button';

type BurgerMenuProps = {
  headerMenus: RoutesItem[];
  isBurgerMenuOpen: boolean;
};

const BurgerMenu: FC<BurgerMenuProps> = memo(
  ({ isBurgerMenuOpen, headerMenus }) => {
    const pathname = usePathname();

    const storeMenus = useMemo(() => {
      return headerMenus.map((item, index) => {
        return (
          <CustomLink
            className={`burger-menu-link ${
              pathname.includes(item.path) ? 'active' : ''
            }`}
            key={index}
            url={item.path}
          >
            <Text className={'p4 font-urbanist white-1000 uppercase'}>
              {item.name}
            </Text>
          </CustomLink>
        );
      });
    }, [headerMenus]);

    return (
      <BurgerMenuStyle
        className={`burger-menu ${isBurgerMenuOpen ? 'active' : ''}`}
      >
        <div className="menus-wrap">
          {storeMenus}
          {/* <CustomLink
					className={`burger-menu-link contact ${pathname.includes(config.routes.contacts.path) ? 'active' : ''}`}
					url={config.routes.contacts.path}>
					<Text
						className={'h3 font-argent royal-blue-1000 uppercase'}
						text={'contacts'}
					/>
				</CustomLink> */}
          <Button
            external
            url={'https://personal-account.owner.one/en/signin'}
            text="CLIENTS ONLY"
            className={'join-wait-list primary inverse'}
          />
        </div>
      </BurgerMenuStyle>
    );
  }
);

export default BurgerMenu;
