import styled from 'styled-components';

export const MainWrapper = styled.footer`
  --paddingTop: var(--sp10x);
  --paddingBottom: var(--sp5x);
  --paddingLeftAndRight: var(--sp6x);
  --faqWidth: 247px;
  --columnWidth: 200px;
  --leftContainerWidth: 384px;
  --appLinksGap: var(--sp3x);

  background-color: var(--dark100);

  padding: var(--paddingTop) var(--paddingLeftAndRight) var(--paddingBottom);
  position: relative;
  z-index: 11;

  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--sp3x);

  &.disable,
  .socialsWrapper.disable,
  .appLinksWrapper.disable,
  .faq.disable {
    display: none;
  }

  .socialsWrapper {
    width: 100%;
    display: flex;
    gap: var(--sp3x);

    .socialIcon {
      width: var(--sp4x);

      .image-cont {
        --proportion: var(--sp4x);
        height: var(--sp4x);
        width: var(--sp4x);

        img {
          object-fit: contain;
        }
      }
    }
  }

  .appLinksWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;

    .appLink {
      display: block;
      border: 1px solid #e3e3e3;
      border-radius: 8px;
      width: fit-content;

      &-image {
        padding: 0;
      }
    }
  }

  //! 1280
  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.tabletSize}) {
    --faqWidth: 186px;
    --columnWidth: 132px;
  }

  // 1024
  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeMin}) and (min-width: 1024px) {
    --faqWidth: 186px;
    --columnWidth: 132px;
    --leftContainerWidth: 249px;
    --appLinksGap: var(--sp2x);

    .appLinksWrapper {
      flex-direction: column;
    }
  }

  // 768
  @media only screen and (max-width: 1023px) and (min-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeS}) {
    --columnWidth: 117px;
    --leftContainerWidth: 249px;
  }

  // Mobile
  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeSMin}) {
    --paddingTop: var(--sp8x);
    --paddingBottom: var(--sp8x);
    --paddingLeftAndRight: var(--sp2x);
    --columnWidth: 167px;

    gap: var(--sp5x);

    .topWrapper {
      flex-direction: column;
      gap: var(--sp5x);
    }

    .leftWrapper {
      width: 100%;
      gap: var(--sp5x);
    }

    .rightWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }

    .faq {
      width: 100%;
    }

    .bottomWrapper {
      display: flex;
      flex-direction: column;
      gap: var(--sp5x);
    }
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`;

export const BottomWrapper = styled.div`
  .rights {
    display: flex;
    width: 100%;
    gap: var(--sp6x);
    align-items: center;

    .text {
      color: var(--white1000);
      font-family: var(--urbanist);
      font-size: var(--sp1-5x);
      line-height: var(--sp2x);
      font-weight: 400;
    }

    .payment {
      display: inline-flex;
      gap: var(--sp3x);
    }

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 12px;
      align-items: flex-start;
    }
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--sp3x);
  width: var(--leftContainerWidth);

  .logo {
    width: var(--sp13x);
    height: var(--sp7x);
    padding: 0;

    .image-cont {
      img {
        object-fit: contain;
      }
    }
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  gap: var(--sp3x);

  .column {
    display: flex;
    flex-direction: column;
    gap: var(--sp3x);
    width: var(--columnWidth);

    .title {
      font-family: var(--urbanist);
      color: var(--white1000);
      font-size: 22px;
      font-weight: 800;
      line-height: 120%;
    }

    .items {
      display: flex;
      flex-direction: column;
      gap: var(--sp2x);

      .text {
        font-family: var(--urbanist);
        font-weight: 400;
        line-height: var(--sp3x);
        font-size: var(--sp2x);
        position: relative;
        width: fit-content;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 1px;
          background-color: var(--white1000);
          transition: var(--trTime);
        }

        @media (hover: hover) {
          &:hover {
            &::before {
              width: 100%;
              left: 0;
            }
          }
        }

        .link {
          color: var(--white1000);
        }
      }
    }

    @media (max-width: 768px) {
      width: auto;
    }
  }
`;

export const FAQ = styled.div`
  background-color: #e3e3e333;
  padding: var(--sp2x);
  width: var(--faqWidth);
  display: flex;
  flex-direction: column;
  gap: var(--sp1-5x);
  height: fit-content;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      .header .arrow svg {
        transform: translateX(5px);
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: var(--sp1-5x);

    .title {
      flex: 1 0 0;
      color: var(--white1000);
      font-family: var(--avenir);
      font-size: var(--sp2x);
      line-height: var(--sp3x);
    }

    .arrow {
      position: relative;
      width: 20px;
      height: 20px;

      svg {
        position: absolute;
        inset: 0;
        transition: transform var(--trTime) ease-in-out;
      }
    }
  }

  .question {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--urbanist);
    color: var(--white1000);
    position: relative;
    padding-left: 8px;

    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: #fff;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.main {
      &::before {
        top: 25%;
      }
    }

    &.secondary {
      white-space: nowrap;
      word-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;
