import styled from 'styled-components';

const BurgerMenuStyle = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	right: 100%;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%),
					linear-gradient(0deg, rgba(31, 31, 31, 0.00) 38.97%, var(--dark100) 100%);
	backdrop-filter: blur(7px);
	z-index: 21;
	transition: 0.5s ease;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: var(--headerHeight);
	overflow-y: auto;

	&.active {
		right: 0;
	}

	.menus-wrap {
		display: flex;
		align-items: start;
		flex-direction: column;
		width: 100%;
		padding: var(--sp4x) var(--sp3x);

		.burger-menu-link {
			position: relative;
			padding: var(--sp1-5x);
			text-align: center;
			font-weight: 500;

			&::before {
				content: '';
				position: absolute;
				bottom: 10px;
				left: calc(50% - 3px);
				width: 6px;
				height: 6px;
				background-color: #CD7764;
				border-radius: 50%;
				opacity: 0;
				transition: opacity var(--trTime) ease;
			}

			&.active {
				font-weight: 700;
				
				&::before {
					opacity: 1;
				}
			}
		}

		.join-wait-list {
			margin-top: var(--sp7x);
			width: var(--sp25x)
		}
	}
`;

export default BurgerMenuStyle;
