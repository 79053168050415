import { forwardRef, PropsWithChildren, SyntheticEvent } from 'react';
import Link from 'next/link';

type CustomLinkProps = Partial<{
  className: string;
  prefetch: boolean;
  scroll: boolean;
  replace: boolean;
  external: boolean;
  props: object;
  target: string;
  onClick: (e: SyntheticEvent) => void;
}> &
  PropsWithChildren & { url: string };

const CustomLink = forwardRef<HTMLAnchorElement, CustomLinkProps>(
  (
    {
      url,
      className,
      prefetch = true,
      scroll = true,
      replace = false,
      external = false,
      children,
      target,
      onClick,
    },
    ref
  ) => {
    return (
      <Link
        ref={ref}
        scroll={scroll}
        replace={replace}
        prefetch={prefetch}
        className={`link-item ${className || ''}`}
        rel={external ? 'noopener noreferrer nofollow' : ''}
        target={external ? '_blank' : target || '_self'}
        href={url}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }
);

export default CustomLink;
