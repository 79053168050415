import styled from 'styled-components';

const ButtonStyle = styled.div`
  --defaultBtnPadLR: 16px;

  --iconBtnSize: var(--sp9x);

  &.primary {
    a,
    button {
      background-color: var(--dark100);
      color: var(--white1000);
      border: 1px solid var(--dark100);
      transition: var(--trTime);
      border-radius: 0;

      &:disabled {
        opacity: 0.5;
      }
    }

    &:hover {
      a,
      button {
        background: #393939;
        color: var(--white1000);
        border: 1px solid #393939;
      }
    }

    &.inverse {
      a,
      button {
        background-color: var(--white1000);
        color: var(--dark100);
      }

      &:hover {
        a,
        button {
          background-color: var(--white1000);
          border-color: #1f1f1f99;
          color: var(--dark100);
        }
      }
    }
  }

  &.secondary {
    a,
    button {
      background-color: var(--gray100);
      color: var(--dark100);
      border: 1px solid var(--gray100);

      &:disabled {
        opacity: 0.5;
        color: var(--dark100);
      }
    }

    @media (hover: hover) {
      &:hover {
        a,
        button {
          color: var(--dark60);
        }
      }
    }
  }

  &.outline {
    a,
    button {
      background: #fff;
      color: var(--dark100);
      border: 1px solid var(--dark100);
      &:disabled {
        opacity: 0.5;
      }
    }

    @media (hover: hover) {
      &:hover {
        a,
        button {
          border: 1px solid var(--dark60);
        }
      }
    }
  }

  &.btn-text {
    width: fit-content;

    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: var(--avenir);
      font-weight: 450;
      font-size: 14px;
      line-height: var(--lineHeightL);
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      isolation: isolate;
      height: 44px;
      padding: 0 var(--defaultBtnPadLR);
    }

    &.btn-link {
      --icon-color: #fff;
      a {
        gap: 8px;
      }

      &.secondary {
        --icon-color: var(--dark100);
        &:hover {
          --icon-color: var(--dark60);
        }
      }

      &:hover .icon {
        transform: rotate(-45deg) translate(4px);
      }

      .icon::before {
        color: var(--icon-color);
        font-size: 16px;
      }

      .icon {
        width: 20px;
        height: 20px;
        transform: rotate(-45deg);
        transition: transform var(--trTime) ease-out;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  /* //! ICON VERSION */
  &.btn-icon {
    width: fit-content;

    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--iconBtnSize);
      height: var(--iconBtnSize);
      text-transform: uppercase;
      border-radius: var(--sp1x);
      cursor: pointer;
      position: relative;
      overflow: hidden;
      isolation: isolate;
      transition: var(--trTime);

      i {
        font-size: var(--p3);
        line-height: 1;

        &:before {
          font-size: var(--p3);
          transition: var(--trTime);
        }
      }
    }
  }

  /* //! GLOBAL DISABLED */
  &.disabled {
    pointer-events: none;

    a,
    button {
      opacity: 0.5;
      cursor: default;
    }
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeL}) {
    --iconBtnSize: var(--sp7x);
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeM}) {
    --iconBtnSize: var(--sp7x);
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeS}) {
    --iconBtnSize: var(--sp6x);
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.tabletSize}) {
    --iconBtnSize: var(--sp6x);
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeS}) {
    --iconBtnSize: var(--sp6x);
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeSMin}) {
    --iconBtnSize: var(--sp5x);
  }
`;
export default ButtonStyle;
