'use client';

import { Fragment, useCallback, useMemo, useState, useRef } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/all';

//* HOC's
import { withDataContext, withLanguageContext, withUIContext } from '@/context';

//* Helpers
import { config, pushDataLayer } from '@/helpers';

//* Components
import Text from '../Text';
import Image from '../Image';
import CustomLink from '../CustomLink';
import SubscribeWidget from './components/SubscribeWidget';

//* Style
import {
  MainWrapper,
  BottomWrapper,
  LeftWrapper,
  RightWrapper,
  TopWrapper,
  FAQ,
} from './style';

gsap.registerPlugin(ScrollTrigger);

const AppFooter = ({ translate, getGlobalData, winWidth, subscribe }) => {
  //! Global Data
  const globalData = getGlobalData();

  //! Next Navigation
  const pathname = usePathname();
  const router = useRouter();

  //! Refs
  const ref = useRef(null);
  const bellRef = useRef(null);

  const getFooterRoutes = useCallback(
    (column) => {
      return Object.entries(config.routes).map((route, ind) => {
        if (route[1].isFooter === column) {
          return (
            <Text key={ind} className="text">
              <CustomLink url={route[1].path} className="link">
                {translate(route[1].name)}
              </CustomLink>
            </Text>
          );
        }
      });
    },
    [config.routes]
  );

  const productItems = useMemo(() => getFooterRoutes('col-1'), []);
  const resourcesItems = useMemo(() => getFooterRoutes('col-2'), []);
  const legalItems = useMemo(() => getFooterRoutes('col-3'), []);

  const socialItems = useMemo(() => {
    return globalData?.socials.length > 0
      ? globalData.socials.map((item, index) => {
          return (
            <CustomLink
              className="socialIcon"
              external
              url={item.url}
              ariaLabel={item.icon.alt}
              key={index}
            >
              <Image src={item.icon.src} alt={item.icon.alt} />
            </CustomLink>
          );
        })
      : null;
  }, [globalData]);

  const appDownloadItems = useMemo(() => {
    return (
      <Fragment>
        <a
          className={`appLink`}
          target="_blank"
          href="https://apps.apple.com/ru/app/owner-one/id6473855308"
        >
          <Image
            width={180}
            height={52}
            className={`contain appLink-image`}
            src={'/images/svg/footer-app-store.svg'}
          />
        </a>
        <a
          className={`appLink`}
          target="_blank"
          href="https://play.google.com/store/apps/details?id=one.owner.app.android"
        >
          <Image
            width={180}
            height={52}
            className={`contain appLink-image`}
            src={'/images/svg/footer-google-play.svg'}
          />
        </a>
      </Fragment>
    );
  }, []);

  const goToFAQ = (e) => {
    GAIFaqLinkClick(e);
    if (pathname !== '/faq') router.push('/faq');
    else window.scrollTo({ top: 0 });
  };

  useGSAP(
    () => {
      let tl;
      ScrollTrigger.create({
        trigger: ref.current,
        start: 'top 100%-=250',
        end: 'bottom 100%-=250',
        onEnter: () => {
          tl = gsap
            .timeline({
              repeat: -1,
              repeatDelay: 5,
            })
            .fromTo(
              bellRef.current,
              {
                rotation: '0deg',
              },
              { rotation: '30deg', duration: 0.2 }
            )
            .fromTo(
              bellRef.current,
              {
                rotation: '30deg',
              },
              { rotation: '-30deg', duration: 0.4 }
            )
            .fromTo(
              bellRef.current,
              {
                rotation: '-30deg',
              },
              { rotation: '30deg', duration: 0.4 }
            )
            .fromTo(
              bellRef.current,
              {
                rotation: '30deg',
              },
              { rotation: '0deg', duration: 0.2 }
            );
        },
        onLeaveBack: () => {
          tl.kill();
          gsap.to(bellRef.current, {
            rotation: '0deg',
            duration: 0.2,
          });
        },
      });
    },
    { dependencies: [ref.current] }
  );

  const GAExternalLinkClick = (e) => {
    if (!e.target.closest('A')) return;
    console.log('yep');
    pushDataLayer({
      event: 'footer_external_link_click',
      element: e.currentTarget,
      target: e.target,
    });
  };

  const GAInternalLinkClick = (e) => {
    if (e.target.nodeName !== 'A') return;
    pushDataLayer({
      event: 'footer_internal_link_click',
      element: e.currentTarget,
      target: e.target,
    });
  };

  const GAIFaqLinkClick = (e) => {
    e.stopPropagation();
    pushDataLayer({
      event: 'footer_faq_click',
      element: e.currentTarget,
      target: e.target,
    });
  };

  return (
    <MainWrapper
      ref={ref}
      className={pathname.includes('not-found') ? 'disable' : ''}
    >
      <TopWrapper className="topWrapper">
        <LeftWrapper className="leftWrapper" onClick={GAExternalLinkClick}>
          <Image className="logo" src="/images/new-logo.svg" priority />
          <div className="formWrapper">
            <SubscribeWidget ref={bellRef} />
          </div>
          <FAQ
            onClick={goToFAQ}
            className={`faq ${winWidth < 1024 ? '' : 'disable'}`}
          >
            <div className="header">
              <Text className="title uppercase" text="faq" />
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M14.8172 10.4422L9.19219 16.0672C9.07491 16.1844 8.91585 16.2503 8.75 16.2503C8.58415 16.2503 8.42509 16.1844 8.30781 16.0672C8.19054 15.9499 8.12465 15.7908 8.12465 15.625C8.12465 15.4591 8.19054 15.3001 8.30781 15.1828L12.8664 10.625H0.625C0.45924 10.625 0.300269 10.5591 0.183058 10.4419C0.0658482 10.3247 0 10.1657 0 9.99998C0 9.83422 0.0658482 9.67525 0.183058 9.55804C0.300269 9.44083 0.45924 9.37498 0.625 9.37498H12.8664L8.30781 4.81717C8.19054 4.69989 8.12465 4.54083 8.12465 4.37498C8.12465 4.20913 8.19054 4.05007 8.30781 3.93279C8.42509 3.81552 8.58415 3.74963 8.75 3.74963C8.91585 3.74963 9.07491 3.81552 9.19219 3.93279L14.8172 9.55779C14.8753 9.61584 14.9214 9.68477 14.9529 9.76064C14.9843 9.83652 15.0005 9.91785 15.0005 9.99998C15.0005 10.0821 14.9843 10.1634 14.9529 10.2393C14.9214 10.3152 14.8753 10.3841 14.8172 10.4422Z"
                    fill="#CD7764"
                  />
                </svg>
              </div>
            </div>
            <Text className="question main">
              How to become an Owner.One user?
            </Text>
            <Text className="question secondary">
              Why am I on the Waitlist and can’t subscribe immediately?
            </Text>
          </FAQ>
          <div className={`socialsWrapper ${winWidth < 768 ? 'disable' : ''}`}>
            {socialItems}
          </div>
          <div className={`appLinksWrapper ${winWidth < 768 ? 'disable' : ''}`}>
            {appDownloadItems}
          </div>
        </LeftWrapper>
        <RightWrapper className="rightWrapper" onClick={GAInternalLinkClick}>
          <FAQ
            onClick={goToFAQ}
            className={`faq ${winWidth < 1024 ? 'disable' : ''}`}
          >
            <div className="header">
              <Text className="title uppercase" text="faq" />
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M14.8172 10.4422L9.19219 16.0672C9.07491 16.1844 8.91585 16.2503 8.75 16.2503C8.58415 16.2503 8.42509 16.1844 8.30781 16.0672C8.19054 15.9499 8.12465 15.7908 8.12465 15.625C8.12465 15.4591 8.19054 15.3001 8.30781 15.1828L12.8664 10.625H0.625C0.45924 10.625 0.300269 10.5591 0.183058 10.4419C0.0658482 10.3247 0 10.1657 0 9.99998C0 9.83422 0.0658482 9.67525 0.183058 9.55804C0.300269 9.44083 0.45924 9.37498 0.625 9.37498H12.8664L8.30781 4.81717C8.19054 4.69989 8.12465 4.54083 8.12465 4.37498C8.12465 4.20913 8.19054 4.05007 8.30781 3.93279C8.42509 3.81552 8.58415 3.74963 8.75 3.74963C8.91585 3.74963 9.07491 3.81552 9.19219 3.93279L14.8172 9.55779C14.8753 9.61584 14.9214 9.68477 14.9529 9.76064C14.9843 9.83652 15.0005 9.91785 15.0005 9.99998C15.0005 10.0821 14.9843 10.1634 14.9529 10.2393C14.9214 10.3152 14.8753 10.3841 14.8172 10.4422Z"
                    fill="#CD7764"
                  />
                </svg>
              </div>
            </div>
            <Text className="question main">
              How to become an Owner.One user?
            </Text>
            <Text className="question secondary">
              Why am I on the Waitlist and can’t subscribe immediately?
            </Text>
          </FAQ>

          <div className="column">
            <Text className="title capitalize" text={`product`} />
            <div className="items">{productItems}</div>
          </div>

          <div className="column">
            <Text className="title capitalize" text={`recourses`} />
            <div className="items">{resourcesItems}</div>
          </div>

          <div className="column">
            <Text className="title capitalize" text={`legal`} />
            <div className="items">{legalItems}</div>
          </div>
        </RightWrapper>
      </TopWrapper>
      <BottomWrapper className="bottomWrapper">
        <div className={`socialsWrapper ${winWidth < 768 ? '' : 'disable'}`}>
          {socialItems}
        </div>
        <div className={`appLinksWrapper ${winWidth < 768 ? '' : 'disable'}`}>
          {appDownloadItems}
        </div>
        <div className="rights">
          <Text className="text">
            © {new Date().getFullYear()} Owner.One,{' '}
            {translate('allRightsReserved')}
          </Text>
          <div className={`payment`}>
            <Image
              width={40}
              height={24}
              className={`contain ft-app-link-image`}
              src={'/images/svg/footer-mc.svg'}
            />
            <Image
              width={45}
              height={24}
              className={`contain ft-app-link-image`}
              src={'/images/svg/footer-visa.svg'}
            />
            <Image
              width={40}
              height={24}
              className={`contain ft-app-link-image`}
              src={'/images/svg/footer-unionpay.svg'}
            />
          </div>
        </div>
      </BottomWrapper>
    </MainWrapper>
  );
};

export default withUIContext(
  withDataContext(withLanguageContext(AppFooter, ['translate']), [
    'getGlobalData',
    'subscribe',
  ]),
  ['winWidth']
);
