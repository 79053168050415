import { memo, MouseEventHandler, ReactNode, useMemo } from 'react';

//* Components
import Icon from '../Icon';
import CustomLink from '../CustomLink';

//* Style
import ButtonStyle from './style';
import { useTranslation } from '@/helpers';

type ButtonProps = {
  btnType?: 'icon' | 'link';
  text?: string;
  iconName?: string;
  variant?: 'primary' | 'secondary' | 'outline' | 'territory' | 'destruction';
  className?: string;
  onClick?: MouseEventHandler;
  url?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  children?: ReactNode;
  external?: boolean;
};

const Button = memo<ButtonProps>(
  ({
    btnType,
    text,
    iconName = '',
    className,
    onClick,
    url,
    type = 'button',
    disabled = false,
    children,
    external,
    variant = 'primary',
  }) => {
    const t = useTranslation();
    const Component = useMemo(() => (url ? CustomLink : 'button'), [url]);

    const customProps = useMemo(() => {
      if (url) {
        return {
          url,
          text,
          external,
        };
      } else {
        return { type, disabled, url: '' };
      }
    }, [url, text, external, type, disabled]);

    let btn;

    switch (btnType) {
      case 'icon':
        btn = (
          <ButtonStyle
            onClick={onClick}
            className={`btn-icon ${className || ''} ${variant || ''}`}
          >
            <Component {...customProps}>
              <Icon name={iconName} />
            </Component>
          </ButtonStyle>
        );

        break;

      case 'link':
        btn = (
          <ButtonStyle
            onClick={onClick}
            className={`btn-text btn-link ${className || ''} ${variant || ''}`}
          >
            <Component {...customProps}>
              <div> {children || t(text)}</div>
              <Icon name={'arrow-short'} />
            </Component>
          </ButtonStyle>
        );
        break;

      default:
        btn = (
          <ButtonStyle
            onClick={onClick}
            className={`btn-text ${className || ''} ${variant || ''}`}
          >
            <Component {...customProps}>{children || t(text)}</Component>
          </ButtonStyle>
        );
    }

    return btn;
  }
);

export default Button;
